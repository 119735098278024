:root {
    --main-color: #6639A6;
    --main-color-alt: #1a1d28;
    --main-text: #fff;
    --button-background: #3490DE;
    --button-text: #fff;
    --link-text: #6639A6;
}

#app {
    max-width: 1600px;
    margin: 0 auto;
    font-family: 'Nunito Sans', Arial;
}

strong,
.strong {
    font-weight: 700;
}

em,
.em {
    font-style: italic;
}

h1 {
    color: var(--main-color-alt);
}

.button {
    height: 40px;
    background: var(--button-background);
    color: var(--button-text);
    border-radius: 5px;
    display: inline-block;
    line-height: 40px;
    text-decoration: none;
    font-weight: 700;
    padding: 0 20px;
    margin: 20px 0 0 0;
    box-shadow: 1px 1px rgb(0 0 0 / 16%), 0 2px 5px rgb(0 0 0 / 8%);
    border: 1px solid transparent;
    cursor: pointer;
}

.button-outline {
    background: transparent;
    border: 1px solid var(--button-background);
}

p {
    line-height: 23px;
}

/*
navigation
 */
header {
    border-bottom: 1px solid var(--main-color);
    padding: 10px 0 20px 0;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}

nav ul li {
    display: inline-block;
    margin: 12px 0 0 0;
    padding: 0 12px;
    vertical-align: middle;
}

nav ul li.logo {
    margin-top: 0;
}

nav a {
    display: inline-block;
    color: var(--link-text);
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 12px;
}

/*
Content
 */
div.content-inner {
    padding: 50px 30px;
}

/*
Home
 */
#home {
    display: block;
    min-height: 500px;
    background-color: var(--main-color);
}

#home,
#home h1,
#home h2 {
    color: var(--main-text);
}

#home .info {
    max-width: 500px;
}

#home h1 {
    font-size: 3em;
}

#home h2 {
    font-size: 1.8em;
}

#home .button-outline {
    margin-right: 10px;
}

/*
Forms
 */
form div.row {
    padding: 20px;
}

form div.row label,
form div.row input,
form div.row select {
    display: block;
}

/*
Sign-up / login
 */
body.login {
    background: var(--main-color-alt);
}

body.login,
body.login h1,
body.login h2 {
    color: var(--main-text);
}

body.login #content {
    width: 400px;
    margin: 0 auto;
}